/**
 * EpiContentArea
 */

import clsx from 'clsx';
import React from 'react';
import {
	CalendarEventListBlockModel,
	CalendarEventPuffWithImageModel,
	NewsModel,
	PuffWithImageModel,
	NewsListBlockModel,
	SectionBlockModel,
	ContentAreaModelTypes,
	PuffModel,
	TextBlockModel,
	DialogListBlockModel,
	DialogCategoryItemModel,
	EditorsBlockModel,
	EditorModel,
	ButtonsAndStuffModel,
	ButtonTypes,
	ServicesListBlockModel,
	ServiceBlockModel,
	SectionColumnBlockModel,
	PuffWithChartModel,
} from 'types';
import {
	AvatarImage,
	Button,
	Cell,
	Grid,
	Icon,
	IconButton,
	Text,
	CalendarEventList,
	CalendarEventListItem,
	CalendarEventPuff,
	NewsList,
	NewsListItem,
	NewsPuff,
	NewsPuffCategory,
} from 'ui-component-library/base';
import Link from 'components/Link';
import {
	DialogList,
	DialogListItem,
	EpiForms,
	NavigationPuff,
	ServiceBlock,
} from 'ui-component-library/uhmse';
import EpiFragments from 'components/EpiFragments';
import EditorList from 'components/EditorList';
import ModalButton from 'components/ModalButton';
import {
	getBlockColumnDesktop,
	getBlockColumnTablet,
	getPuffMarginBottom,
	getSectionColumnDesktop,
	getSectionColumnTablet,
} from './helpers';
import ChartPuff from 'components/ChartPuff';
import { useIsMobile, useIsTablet } from 'hooks/useMediaQuery';

interface Props {
	/** Epi property containing content for the content area */
	content?: any;

	/** Epi properties for on page editing */
	[htmlAttributes: string]: any;
}

/** Epi content area for handling blocks */
const EpiContentArea: React.FC<Props> = ({
	content = [],
	...htmlAttributes
}) => {
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const renderContentBlock = (
		block: ContentAreaModelTypes,
		headingLevel: 'h2' | 'h3',
		index: number
	) => {
		switch (block.modelType) {
			case 'SectionBlock':
				const layoutData = block as SectionBlockModel;
				return (
					<div
						data-cid="SectionBlock"
						className={clsx(
							layoutData.background ? 'bg-greyLightest py-16' : 'my-16'
						)}
						key={index}
					>
						<Grid padding={false} className="pl-4 lg:pl-6 -mb-10 md:-mb-16">
							{layoutData.items &&
								layoutData.items.map((item: any, indexLayoutData: number) => {
									const sectionHeadingLevel = 'h2';
									return (
										<React.Fragment key={indexLayoutData}>
											{renderContentBlock(
												item,
												sectionHeadingLevel,
												indexLayoutData
											)}
										</React.Fragment>
									);
								})}
						</Grid>
					</div>
				);

			case 'SectionColumnSmall':
			case 'SectionColumnMedium':
			case 'SectionColumnLarge':
				const sectionColumnData = block as SectionColumnBlockModel;

				return (
					<Cell
						span={12}
						tablet={getSectionColumnTablet(block.modelType)}
						desktop={getSectionColumnDesktop(block.modelType)}
						data-block-title={sectionColumnData?.heading}
						id={sectionColumnData.id}
					>
						<div>
							{sectionColumnData.heading && (
								<Text as={headingLevel} margin={false} className="mb-5">
									{sectionColumnData.heading}
								</Text>
							)}
							{sectionColumnData.text && (
								<Text as="p" margin={false} className="text-black mb-4">
									{sectionColumnData.text}
								</Text>
							)}
						</div>
						<Grid padding={false} className="-mr-4">
							{sectionColumnData?.items &&
								sectionColumnData?.items.length > 0 &&
								sectionColumnData?.items?.map((item, index) => {
									const blockHeadingLevel = sectionColumnData.heading
										? 'h3'
										: 'h2';
									return (
										<Cell
											span={12}
											tablet={getBlockColumnTablet(
												block.modelType,
												item.modelType
											)}
											desktop={getBlockColumnDesktop(
												block.modelType,
												item.modelType
											)}
											className={clsx(
												item.modelType === 'Puff' &&
													`puff--border-top puff--border-bottom
													${getPuffMarginBottom(
														block.modelType,
														isMobile,
														isTablet,
														sectionColumnData.items?.length as number,
														index
													)}`
											)}
											key={index}
										>
											{renderContentBlock(item, blockHeadingLevel, index)}
										</Cell>
									);
								})}
						</Grid>
					</Cell>
				);
			case 'Puff':
				const puffData = block as PuffModel;
				return (
					<NavigationPuff
						url={puffData.url}
						heading={puffData.heading}
						text={puffData.text}
						headingLevel={headingLevel}
						LinkComponent={Link}
						key={index}
					/>
				);
			case 'PuffWithNoImage':
			case 'PuffWithImage':
				const newsPuffData = block as PuffWithImageModel;
				return (
					<NewsPuff
						src={newsPuffData.image}
						alt={newsPuffData.alt}
						heading={newsPuffData.heading}
						text={newsPuffData.text}
						published={newsPuffData.published}
						url={newsPuffData.url}
						LinkComponent={Link}
						className="mb-10 md:mb-16"
						headingLevel={headingLevel}
						variant={newsPuffData.isRounded ? 'rounded' : 'standard'}
						key={index}
					>
						{newsPuffData.categories &&
							newsPuffData.categories.length > 0 &&
							newsPuffData.categories.map((category, index) => (
								<NewsPuffCategory
									key={`puff-${index}`}
									text={category.text}
									url={category.url}
									LinkComponent={Link}
								/>
							))}
					</NewsPuff>
				);
			case 'PuffWithChart':
				const chartPuffData = block as PuffWithChartModel;
				return (
					<ChartPuff
						heading={chartPuffData.heading}
						headingLevel={headingLevel}
						text={chartPuffData.text}
						published={chartPuffData.published}
						url={chartPuffData.url}
						LinkComponent={Link}
						chartProperties={chartPuffData.chartProperties}
						className="mb-10 md:mb-16"
						key={`chart-puff-${index}`}
					>
						{chartPuffData.categories &&
							chartPuffData.categories.length > 0 &&
							chartPuffData.categories.map((category, index) => (
								<NewsPuffCategory
									key={`chart-puff-${index}`}
									text={category.text}
									url={category.url}
									LinkComponent={Link}
								/>
							))}
					</ChartPuff>
				);
			case 'CalendarEventPuffWithNoImage':
			case 'CalendarEventPuffWithImage':
				const eventPuffData = block as CalendarEventPuffWithImageModel;
				return (
					<CalendarEventPuff
						src={eventPuffData.image}
						alt={eventPuffData.alt}
						heading={eventPuffData.heading}
						text={eventPuffData.text}
						eventDate={eventPuffData.eventDate}
						eventMonth={eventPuffData.eventMonth}
						url={eventPuffData.url}
						category={eventPuffData.category}
						LinkComponent={Link}
						headingLevel={headingLevel}
						className="mb-10 md:mb-16"
						key={index}
					/>
				);

			case 'CalendarEventListBlock':
				const eventListData = block as CalendarEventListBlockModel;
				return (
					<div key={index} className="mb-10 md:mb-16">
						<CalendarEventList borderTop={true} className="mb-8">
							{eventListData.items.map((event, index) => {
								return (
									<CalendarEventListItem
										startDay={event.start.day}
										startMonth={event.start.monthNameShort}
										endDay={event.end.day}
										endMonth={event.end.monthNameShort}
										heading={event.heading}
										category={event.category}
										url={event.url}
										hasEndDate={eventListData.hasMultiDayEvents}
										LinkComponent={Link}
										headingLevel={headingLevel}
										key={index}
									/>
								);
							})}
						</CalendarEventList>
						<div className="flex justify-around">
							<Button
								variant="secondary"
								href={eventListData.link}
								LinkComponent={Link}
								as="a"
								data-button-action="See more"
							>
								{eventListData.linkText}
								<Icon
									icon="chevron"
									direction="left"
									size={0.75}
									className="ml-2"
									aria-hidden={true}
								/>
							</Button>
						</div>
					</div>
				);
			case 'NewsListBlock':
				const newsListData = block as NewsListBlockModel;
				return (
					<div key={index} className="mb-10 md:mb-16">
						<NewsList className="mb-8">
							{newsListData.items &&
								newsListData.items.map((news: NewsModel, index: number) => {
									return (
										<NewsListItem
											date={news.published}
											heading={news.heading}
											url={news.url}
											LinkComponent={Link}
											headingLevel={headingLevel}
											key={index}
										/>
									);
								})}
						</NewsList>
						<div className="flex justify-around">
							<Button
								variant="secondary"
								href={newsListData.link}
								LinkComponent={Link}
								as="a"
								data-button-action="See more"
							>
								{newsListData.linkText}
								<Icon
									icon="chevron"
									direction="left"
									size={0.75}
									className="ml-2"
									aria-hidden={true}
								/>
							</Button>
						</div>
					</div>
				);
			case 'TextBlock':
				const textData = block as TextBlockModel;
				return (
					<div className="mb-10 md:mb-16">
						<EpiFragments
							prop="text"
							key={index}
							fragments={textData.text.fragments}
						/>
					</div>
				);

			case 'Dialogs':
			case 'DialogsSmall':
			case 'DialogsMedium':
			case 'DialogsLarge':
				const dialogListData = block as DialogListBlockModel;
				return (
					<div key={index} className="mb-10 md:mb-16">
						<Text as="h2" margin={false} className="text-black mb-5">
							{dialogListData.heading}
						</Text>
						<DialogList>
							{dialogListData.dialogs &&
								dialogListData.dialogs.map(
									(item: DialogCategoryItemModel, index: number) => {
										return (
											<DialogListItem
												heading={item.heading}
												url={item.url}
												numberOfComments={item.numberOfComments}
												LinkComponent={Link}
												headingLevel="h3"
												key={index}
											/>
										);
									}
								)}
						</DialogList>
						{dialogListData.url && dialogListData.linkText && (
							<div className="flex mt-8">
								<Button
									variant="secondary"
									href={dialogListData.url}
									as="a"
									LinkComponent={Link}
									data-button-action="See more"
								>
									{dialogListData.linkText}
									<Icon
										icon="chevron"
										direction="left"
										size={0.75}
										className="ml-2"
										aria-hidden={true}
									/>
								</Button>
							</div>
						)}
					</div>
				);
			case 'Editors':
			case 'EditorsSmall':
			case 'EditorsMedium':
			case 'EditorsLarge':
				const editorsData = block as EditorsBlockModel;
				return (
					<EditorList
						showMoreButtonText={editorsData.showMoreButtonText}
						showLessButtonText={editorsData.showLessButtonText}
						showNumberOfEditors={editorsData.showNumberOfEditors}
						key={index}
					>
						{editorsData?.editors?.map(
							(editor: EditorModel, subIndex: number) => {
								return (
									<div key={subIndex} className="flex items-center mb-4 w-88">
										<AvatarImage image={editor.image} uhmIcon={true} />
										<div className="ml-3">
											<h3 className="text-h3">{editor.name}</h3>
											<p className="text-h5 text-greyDarker leading-6">
												{editor.title}
											</p>
										</div>
									</div>
								);
							}
						)}
					</EditorList>
				);
			case 'ButtonsAndStuff':
				const buttonsData = block as ButtonsAndStuffModel;
				return (
					<div
						key={index}
						className="mb-10 md:mb-16"
						data-cid="AskQuestionBlock"
					>
						{buttonsData.heading && (
							<Text as="h2" margin={false} className="text-black mb-5">
								{buttonsData.heading}
							</Text>
						)}
						{buttonsData.text && (
							<Text as="p" margin={false} className="text-black -mt-5 mb-5">
								{buttonsData.text}
							</Text>
						)}
						{buttonsData.items.map((item: ButtonTypes, subIndex: number) => {
							switch (item.modelType) {
								case 'AskQuestion':
									const handleSubmit = (values?: any) => {
										if (window && window.dataLayer && window.dataLayer.push) {
											// Get field value from "Frågerubrik", "Frågekategori" and "Kundkategori" by id from production www.upphandlingsmyndigheten.se"
											// Don't forget to update field id if the field order has been modified in EPI
											let questionTitle = values.get('__field_3170'); // Frågerubrik
											let questionCategory = values.get('__field_3172'); // Frågekategori
											let customerCategory = values.get('__field_3173'); // Kundkategori

											window.dataLayer.push({
												event: 'QuestionSubmit',
												questionTitle: questionTitle,
												questionCategory: questionCategory,
												customerCategory: customerCategory,
											});
										}
									};
									return (
										<div className="mb-6" key={subIndex}>
											<ModalButton
												buttonText={item.text}
												closeModalButtonText={item.closeModalButtonText}
												id={`AskQuestion-${buttonsData.id}`}
											>
												<div className="mt-6 lg:w-176">
													<EpiForms
														{...item.formData}
														onEpiFormSubmit={handleSubmit}
													/>
												</div>
											</ModalButton>
										</div>
									);

								case 'ChatWithUs':
									const handleOnClick = () => {
										if (
											typeof window !== 'undefined' &&
											window.$kundo_chat &&
											window.$kundo_chat.start_chat
										) {
											window.$kundo_chat.start_chat();
										}
									};
									return (
										<div className="mb-4" key={subIndex}>
											<IconButton
												icon="messageSquare"
												id={`ChatWithUs-${buttonsData.id}`}
												onClick={handleOnClick}
												heading={item.text}
											>
												<p className="text-h5 text-greyDarker leading-6">
													{item.time}
												</p>
											</IconButton>
										</div>
									);
								case 'CallUs':
									return (
										<div className="mb-4" key={subIndex}>
											<IconButton
												icon="phone"
												tag="a"
												url={`tel: ${item.phone}`}
												id={`CallUs-${buttonsData.id}`}
												heading={item.text}
											>
												<p className="text-h5 text-greyDarker leading-6">
													{item.time}
												</p>
											</IconButton>
										</div>
									);
								case 'text':
									return (
										<div className="mt-8 mb-4" key={subIndex}>
											<p className="text-h5 text-greyDarker leading-6">
												{item.text}
											</p>
										</div>
									);
								default:
									return null;
							}
						})}
					</div>
				);
			case 'ServicesList':
				const serviceListData = block as ServicesListBlockModel;
				return (
					<div key={index} className="mb-10 md:mb-16">
						{serviceListData.items.map(
							(item: ServiceBlockModel, subIndex: number) => {
								return (
									<div className="mb-2" key={subIndex}>
										<ServiceBlock
											heading={item.heading}
											headingLevel={headingLevel}
											text={item.text}
											url={item.url}
											icon={item.icon}
											LinkComponent={Link}
										/>
									</div>
								);
							}
						)}
					</div>
				);
			default:
				return null;
		}
	};
	// const epi = useSelector(selectEpi);
	// const epiProps: any = {};

	// if (epi?.beta?.isEditable && prop) {
	// 	epiProps['data-epi-property-name'] = prop;
	// 	epiProps['data-epi-property-render'] = 'none';
	// }

	return (
		<div {...htmlAttributes}>
			{content.map((block: ContentAreaModelTypes, index: number) =>
				renderContentBlock(block, 'h2', index)
			)}
		</div>
	);
};

export default EpiContentArea;
