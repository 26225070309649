/**
 * StatisticService
 */

import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useMachine } from '@xstate/react';
import { useHistory } from 'react-router-dom';
import {
	statisticServiceMachine,
	OptionDataFiltersGroup,
	OptionData,
	OptionDataParameterGroup,
	OptionDataParameterDimension,
} from 'state-machines/statisticService.machine';
import Text from 'components/Text';
import {
	Grid,
	Icon,
	Cell,
	Form,
	RadioButtonBase,
	CheckboxBase,
	Tooltip,
	ButtonSupportive,
	FilterButton,
	FilterButtonGroup,
	Alert,
	Button,
} from 'ui-component-library/base';
import StatisticServiceChart from './StatisticService.chart';
import StatisticTable from 'components/StatisticTable';
import { useIsMobile } from 'hooks/useMediaQuery';
import ScrollableTable from 'components/ScrollableTable';
import ExportDataDropdown from 'components/ExportDataDropdown';
import ExportDataOption from 'components/ExportDataDropdown/ExportDataOption';
import StatisticsServiceFilter from './StatisticsServiceFilter';
import StatisticsServiceFilterOption from './StatisticsServiceFilterOption';

const diagramExportOptions = [
	{ value: 'svg', caption: 'Till SVG' },
	{ value: 'png', caption: 'Till PNG' },
	{ value: 'jpeg', caption: 'Till JPEG' },
];

const tableExportOptions = [
	{ value: 'csv', caption: 'Till CSV' },
	{ value: 'excel', caption: 'Till Excel' },
];

const dataButtonActions = {
	tooltip: 'Tooltip',
	rensaUrval: 'Rensa urval',
	resultAvDittUrval: 'Resultat av ditt urval',
	tableButton: 'Tabell',
	diagramButton: 'Diagram',
};

const renderParameterGroup = (
	options: OptionData[],
	heading: string,
	selectedParameters: string[],
	send: any,
	isLastColumn: boolean,
	description?: string
): JSX.Element | null => {
	if (!options) {
		return null;
	}
	return (
		<div className="border-t border-greySemiLight p-4">
			<fieldset>
				<legend className="font-bold mb-4">
					{heading}
					{description && (
						<Tooltip
							className="leading-3 align-top font-normal"
							label={`Beskrivning av ${heading}`}
							description={description}
							position={isLastColumn ? 'left' : 'right'}
							data-button-action={dataButtonActions.tooltip}
						/>
					)}
				</legend>
				{options.map((option) => {
					return (
						<CheckboxBase
							id={option.id}
							key={option.id}
							name={option.name}
							value={option.value}
							theme="teal"
							checked={selectedParameters.indexOf(option.value) !== -1}
							disabled={option.disabled}
							className="mr-4"
							onChange={(e: any) => {
								send({
									type: e.target.checked ? 'ADD_PARAMETER' : 'REMOVE_PARAMETER',
									data: e.target.value,
								});
								handleActionClick(e, 'Fler variabler', option.text);
							}}
						>
							<span>
								{option.text}
								{option.description && (
									<Tooltip
										className="leading-3 align-top font-normal"
										label={`Beskrivning av ${option.text}`}
										description={option.description}
										position={isLastColumn ? 'left' : 'right'}
									/>
								)}
							</span>
						</CheckboxBase>
					);
				})}
			</fieldset>
		</div>
	);
};

const handleActionClick = (
	e: any,
	parameterName: string,
	variableName: string
) => {
	const { type, checked } = e.target;

	const getFilterAction = () => {
		switch (type) {
			case 'radio':
				return 'selection';
			case 'checkbox':
				if (checked) {
					return 'selection';
				} else {
					return 'removal';
				}
			default:
				break;
		}
	};

	if (window.dataLayer && window.dataLayer.push) {
		window.dataLayer.push({
			event: 'statistics_database_filter',
			parameterName: parameterName,
			variableName: variableName,
			filterAction: getFilterAction(),
		});
	}
};

/** StatisticService component. */
const StatisticService: React.FC = () => {
	const history = useHistory();
	const chartRef = useRef<any>(null);
	const [current, send] = useMachine(statisticServiceMachine, {
		context: {
			apiUrl: '/api/sv/statisticsservice/bridgeapi/statistics',
			// uncomment this below to be able to test with prep api and disable cors in the browser to make it work
			// apiUrl:
			// 	'https://dkn-prep.upphandlingsmyndigheten.se/api/sv/statisticsservice/bridgeapi/statistics',
			router: history,
			itemsPerPage: 20,
		},
		devTools: true,
	});
	const resultDivRef = useRef<HTMLDivElement>(null);
	const resultBarRef = useRef<HTMLDivElement>(null);
	const [parametersExpanded, setParametersExpanded] = useState<boolean>(false);
	const isMobile = useIsMobile();

	const srollToResult = () => {
		if (resultDivRef.current && resultBarRef.current) {
			window.scrollTo({
				top: resultDivRef.current.offsetTop - resultBarRef.current.offsetHeight,
				behavior: 'smooth',
			});
		}
	};

	const renderRadioButton = (
		option: OptionData,
		checked: boolean = false,
		onChange: any
	): JSX.Element => (
		<RadioButtonBase
			id={option.id}
			key={option.id}
			name={option.name}
			value={option.value}
			checked={checked}
			className="mr-4"
			onChange={onChange}
			theme="teal"
		>
			<span>
				{option.text}
				{option.description && (
					<Tooltip
						className="leading-3 align-top font-normal -mt-1"
						label={`Beskrivning av ${option.text}`}
						description={option.description}
						position={isMobile ? 'center' : 'right'}
						data-button-action={dataButtonActions.tooltip}
					/>
				)}
			</span>
		</RadioButtonBase>
	);

	const handleExportData = (exportFormat: string) => {
		let format =
			current.context.selectedResultFormat === 'table' ||
			current.context.selectedResultFormat === 'matrixtable'
				? `Tabell: ${current.context.selectedResultFormat}`
				: `Diagram: ${current.context.selectedChartType}`;

		if (window.dataLayer && window.dataLayer.push) {
			window.dataLayer.push({
				event: 'statistics_database_export',
				format: format,
				exportFormat: exportFormat,
				parameter1: `Statistikområde: ${current.context.selectedArea}`,
				parameter2: `Statistikprodukt: ${current.context.selectedProduct}`,
				parameter3: `Variabel: ${current.context.selectedUnit}`,
				parameter4: `Definiera urval: ${current.context.selectedDistributedUnit}`,
				parameter5: `Fler variabler: ${current?.context?.selectedParameters
					?.map((param) => `${param}`)
					?.join(', ')}`,
				parameter6: `Tidsperiod: ${
					(current.context.selectedYear?.length > 0 &&
						current.context.selectedYear?.map((year) => `${year}`)?.join(', ') +
							`,`) ||
					''
				} ${current.context.selectedTimeFragment}`,
				parameter7: `Mått: ${current.context.selectedMeasurement}`,
			});
		}
	};

	const onScroll = () => {
		if (resultDivRef.current && window) {
			if (
				window.pageYOffset + window.innerHeight >=
				resultDivRef.current.offsetTop +
					resultDivRef.current.offsetHeight * 0.75
			) {
				let format =
					current.context.selectedResultFormat === 'table' ||
					current.context.selectedResultFormat === 'matrixtable'
						? `Tabell: ${current.context.selectedResultFormat}`
						: `Diagram: ${current.context.selectedChartType}`;

				if (window.dataLayer && window.dataLayer.push) {
					window.dataLayer.push({
						event: 'statistics_database_view',
						format: format,
						parameter1: `Statistikområde: ${current.context.selectedArea}`,
						parameter2: `Statistikprodukt: ${current.context.selectedProduct}`,
						parameter3: `Variabel: ${current.context.selectedUnit}`,
						parameter4: `Definiera urval: ${current.context.selectedDistributedUnit}`,
						parameter5: `Fler variabler: ${current?.context?.selectedParameters
							?.map((param) => `${param}`)
							?.join(', ')}`,
						parameter6: `Tidsperiod: ${
							(current.context.selectedYear?.length > 0 &&
								current.context.selectedYear
									?.map((year) => `${year}`)
									?.join(', ') + `,`) ||
							''
						} ${current.context.selectedTimeFragment}`,
						parameter7: `Mått: ${current.context.selectedMeasurement}`,
					});
				}

				if (window) {
					window.removeEventListener('scroll', onScroll);
				}
			}
		}
	};

	useEffect(() => {
		send('INITIALIZE');
		// setTimeout(() => send('SELECT_AREA'), 1000);
		// setTimeout(() => send('SELECT_PRODUCT'), 2000);
		// setTimeout(() => send('SELECT_UNIT'), 3000);
	}, []);

	useEffect(() => {
		if (current.context.result) {
			if (resultDivRef.current) {
				if (window) {
					window.addEventListener('scroll', onScroll);
				}

				return () => {
					if (window) {
						window.removeEventListener('scroll', onScroll);
					}
				};
			}
		}
	}, [current.context.result]);

	useEffect(() => {
		if (current.context.resultError?.message) {
			if (window.dataLayer && window.dataLayer.push) {
				let format =
					current.context.selectedResultFormat === 'table' ||
					current.context.selectedResultFormat === 'matrixtable'
						? 'Tabell'
						: 'Diagram';
				window.dataLayer.push({
					event: 'statisticsServiceError',
					errorType: current.context.resultError.message,
					format: format,
				});
			}
		}
	}, [current.context.resultError?.message]);

	return (
		<div>
			<Grid margin={false} padding={false} className="lg:px-6 px-4">
				<Cell span={12} className="flex justify-between items-center">
					<Text as="h2">
						Gör ditt val
						{current.matches('initialize') && (
							<Icon
								icon="loader"
								size={2}
								animate="spin"
								color="#75726B"
								className="inline-block ml-2"
							/>
						)}
					</Text>
					<div
						className={clsx(
							'transition-opacity duration-500 ease-in-out flex justify-end mb-4 mt-7',
							!current.context.selectedArea && 'opacity-0'
						)}
					>
						<ButtonSupportive
							type="button"
							variant="rounded"
							onClick={() => {
								send('CLEAR_ALL');
							}}
							data-button-action={dataButtonActions.rensaUrval}
						>
							Rensa urval
							<Icon icon="trashCan" aria-hidden={true} className="ml-2 -mt-1" />
						</ButtonSupportive>
					</div>
				</Cell>
			</Grid>

			<div>
				<Grid margin={false} padding={false} className="lg:px-6 px-4">
					<Cell span={12}>
						<div
							className={clsx(
								'transition-opacity duration-500 ease-in-out border-b border-grey',
								!current.context.areas && 'opacity-25'
							)}
						>
							<Text as="h3">
								Statistikområde
								<span className="font-normal text-greyDarker ml-1">
									(Obligatoriskt)
								</span>
								<Tooltip
									className="leading-3 align-top"
									label="Beskrivning av statistikområde"
									description="Statistiken är indelad i olika områden. Ett område innehåller en eller flera statistikprodukter."
									position={isMobile ? 'left' : 'center'}
									data-button-action={dataButtonActions.tooltip}
								/>
								{current.matches('loadingAreas') && (
									<Icon
										icon="loader"
										size={2}
										animate="spin"
										color="#75726B"
										className="inline-block ml-2"
									/>
								)}
							</Text>
							<Text as="p">Välj ett statistikområde</Text>
							{current.context.areas && (
								<form className="mb-2">
									<fieldset className="flex flex-wrap flex-col md:flex-row">
										{current.context.areas.map((option) =>
											renderRadioButton(
												option,
												option.value === current.context.selectedArea,
												(e: any) => {
													send({
														type: 'SELECT_AREA',
														data: e.target.value,
													});
													handleActionClick(e, 'Statistikområde', option.text);
												}
											)
										)}
										{/* <RadioButtonBase
											id={'area-rattfallsstatistik'}
											key={'area-rattfallsstatistik'}
											name={'area'}
											value={'rattfallsstatistik'}
											className="mr-4"
											theme="teal"
											disabled={true}
										>
											<span>
												{'Rättsfallsstatistik (kommer 2023)'}
												<Tooltip
													className="leading-3 align-top font-normal -mt-1"
													label="Beskrivning av rättsfallsstatistik"
													description={
														'Rättsfallsstatistik visar produkter som är baserade på uppgifter ur domar och beslut.'
													}
													position={isMobile ? 'left' : 'center'}
													data-button-action={dataButtonActions.tooltip}
												/>
											</span>
										</RadioButtonBase> */}
									</fieldset>
								</form>
							)}
							<div
								aria-live="polite"
								aria-atomic="true"
								className="md:w-1/2 lg:w-4/12 mb-6"
							>
								{current.matches('errorProduct') && (
									<Alert variant="danger">
										<Text as="p" margin={false} className="mb-0">
											{current.context.error}
										</Text>
									</Alert>
								)}
							</div>
						</div>

						<div
							className={clsx(
								'transition-opacity duration-500 ease-in-out border-b border-grey',
								!current.context.products && 'opacity-25'
							)}
						>
							<Text as="h3">
								Statistikprodukt
								<span className="font-normal text-greyDarker ml-1">
									(Obligatoriskt)
								</span>
								<Tooltip
									className="leading-3 align-top"
									label="Beskrivning av statistikprodukt"
									description="Statistiken är indelad i olika statistikprodukter. En statistikprodukt redovisar statistik från en specifik undersökning eller insamling."
									position={isMobile ? 'left' : 'center'}
									data-button-action={dataButtonActions.tooltip}
								/>
								{current.matches('loadingProducts') && (
									<Icon
										icon="loader"
										size={2}
										animate="spin"
										color="#75726B"
										className="inline-block ml-2"
									/>
								)}
							</Text>
							<Text as="p">Välj en statistikprodukt</Text>
							{current.context.products && (
								<form className="mb-2">
									<fieldset className="flex flex-wrap flex-col md:flex-row">
										{current.context.products.map((option) =>
											renderRadioButton(
												option,
												option.value === current.context.selectedProduct,
												(e: any) => {
													send({
														type: 'SELECT_PRODUCT',
														data: e.target.value,
													});
													handleActionClick(e, 'Statistikprodukt', option.text);
												}
											)
										)}
									</fieldset>
								</form>
							)}
							<div
								aria-live="polite"
								aria-atomic="true"
								className="md:w-1/2 lg:w-4/12 mb-6"
							>
								{current.matches('errorUnit') && (
									<Alert variant="danger">
										<Text as="p" margin={false} className="mb-0">
											{current.context.error}
										</Text>
									</Alert>
								)}
							</div>
						</div>

						<div
							className={clsx(
								'transition-opacity duration-500 ease-in-out border-b border-grey',
								!current.context.units && 'opacity-25'
							)}
						>
							<Text as="h3">
								Variabel
								<span className="font-normal text-greyDarker ml-1">
									(Obligatoriskt)
								</span>
								<Tooltip
									className="leading-3 align-top"
									label="Beskrivning av värde"
									description="Statistiken redovisas med flera variabelvärden. Välj den variabel som du vill få statistik för."
									position={'center'}
									data-button-action={dataButtonActions.tooltip}
								/>
								{current.matches('loadingUnits') && (
									<Icon
										icon="loader"
										size={2}
										animate="spin"
										color="#75726B"
										className="inline-block ml-2"
									/>
								)}
							</Text>
							<Text as="p">Välj en variabel</Text>
							{current.context.units && (
								<form className="mb-2">
									<fieldset className="flex flex-wrap flex-col md:flex-row">
										{current.context.units.map((option) =>
											renderRadioButton(
												option,
												option.value === current.context.selectedUnit,
												(e: any) => {
													send({
														type: 'SELECT_UNIT',
														data: e.target.value,
													});
													handleActionClick(e, 'Variabel', option.text);
												}
											)
										)}
									</fieldset>
								</form>
							)}
							<div
								aria-live="polite"
								aria-atomic="true"
								className="md:w-1/2 lg:w-4/12 mb-6"
							>
								{current.matches('errorDistributedUnit') && (
									<Alert variant="danger">
										<Text as="p" margin={false} className="mb-0">
											{current.context.error}
										</Text>
									</Alert>
								)}
							</div>
						</div>
						<div
							className={clsx(
								'transition-opacity duration-500 ease-in-out border-b border-grey',
								!current.context.distributedUnits && 'hidden'
							)}
						>
							<Text as="h3">
								Definiera urval
								<span className="font-normal text-greyDarker ml-1">
									(Obligatoriskt)
								</span>
								<Tooltip
									className="leading-3 align-top"
									label="Beskrivning av definiera urval"
									description="Statistiken delas in efter innehåll. Genom att definiera urvalet väljer du vilket innehåll som ska visas."
									position={isMobile ? 'left' : 'right'}
									data-button-action={dataButtonActions.tooltip}
								/>
								{current.matches('loadingDistributedUnits') && (
									<Icon
										icon="loader"
										size={2}
										animate="spin"
										color="#75726B"
										className="inline-block ml-2"
									/>
								)}
							</Text>
							<Text as="p">Definiera urvalet genom att välja en variabel</Text>
							{current.context.distributedUnits && (
								<form className="mb-4">
									<fieldset className="flex flex-wrap flex-col md:flex-row">
										{current.context.distributedUnits.map((option) =>
											renderRadioButton(
												option,
												option.value ===
													current.context.selectedDistributedUnit,
												(e: any) => {
													send({
														type: 'SELECT_DISTRIBUTED_UNIT',
														data: e.target.value,
													});
													handleActionClick(e, 'Definiera urval', option.text);
												}
											)
										)}
									</fieldset>
								</form>
							)}
						</div>

						{current.context.parameters?.length === 1 &&
						current.context.parameters[0].dimensions?.length === 1 &&
						current.context.parameters[0]?.dimensions[0]?.properties?.length >
							0 ? (
							<div
								className={clsx(
									'transition-opacity duration-500 ease-in-out border-b border-grey',
									!current.context.parameters && 'hidden'
								)}
							>
								<Text as="h3">
									{current.context.parameters[0]?.dimensions[0].heading}
									<span className="font-normal text-greyDarker ml-1">
										(Obligatoriskt)
									</span>
									{current.context.parameters[0]?.dimensions[0].description && (
										<Tooltip
											className="leading-3 align-top"
											label={`Beskrivning av ${current.context.parameters[0]?.dimensions[0].heading}`}
											description={
												current.context.parameters[0]?.dimensions[0].description
											}
											position="right"
											data-button-action={dataButtonActions.tooltip}
										/>
									)}
									{current.matches('result.loadingStatistics') && (
										<Icon
											icon="loader"
											size={2}
											animate="spin"
											color="#75726B"
											className="inline-block ml-2"
										/>
									)}
								</Text>
								<Text as="p">
									{current.context.parameters[0]?.dimensions[0].text}
								</Text>
								{current.context.parameters[0].dimensions[0].properties && (
									<form className="mb-4">
										<fieldset className="flex flex-wrap">
											{current.context.parameters[0].dimensions[0].properties
												.length === 1
												? current.context.parameters[0].dimensions[0].properties.map(
														(option) =>
															renderRadioButton(
																option,
																option.value ===
																	current.context.selectedParameters[0],
																(e: any) => {
																	send({
																		type: e.target.checked
																			? 'ADD_PARAMETER'
																			: 'REMOVE_PARAMETER',
																		data: e.target.value,
																	});
																}
															)
												  )
												: current.context.parameters[0].dimensions[0].properties.map(
														(option) => (
															<CheckboxBase
																id={option.id}
																key={option.id}
																name={option.name}
																value={option.value}
																checked={
																	current.context.selectedParameters.indexOf(
																		option.value
																	) !== -1
																}
																theme="teal"
																className="mr-4"
																onChange={(e: any) => {
																	send({
																		type: e.target.checked
																			? 'ADD_PARAMETER'
																			: 'REMOVE_PARAMETER',
																		data: e.target.value,
																	});
																	if (
																		current.context.parameters &&
																		current.context.parameters[0].dimensions &&
																		current.context.parameters[0]?.dimensions[0]
																			.heading
																	)
																		handleActionClick(
																			e,
																			current.context.parameters[0]
																				?.dimensions[0].heading,
																			option.text
																		);
																}}
															>
																<span>
																	{option.text}
																	{option.description && (
																		<Tooltip
																			className="leading-3 align-top"
																			label={`Beskrivning av ${option.text}`}
																			description={option.description}
																			position="right"
																			data-button-action={
																				dataButtonActions.tooltip
																			}
																		/>
																	)}
																</span>
															</CheckboxBase>
														)
												  )}
										</fieldset>
									</form>
								)}
							</div>
						) : (
							<>
								<div
									className={clsx(
										'transition-opacity duration-500 ease-in-out border-b border-grey',
										!current.context.parameters && 'hidden'
									)}
								>
									<Text as="h3">
										Fler variabler
										<Tooltip
											className="leading-3 align-top"
											label="Beskrivning av fler parametrar"
											description="Statistiken innehåller flera olika variabler. Genom att välja en eller flera variabler kan du förfina ditt urval av statistik."
											position="right"
											data-button-action={dataButtonActions.tooltip}
										/>
										{current.matches('result.loadingStatistics') && (
											<Icon
												icon="loader"
												size={2}
												animate="spin"
												color="#75726B"
												className="inline-block ml-2"
											/>
										)}
									</Text>
									<Text
										as="p"
										margin={false}
										className={clsx(isMobile ? 'mb-8' : 'mb-4')}
									>
										{
											// isMobile
											// 	? 'Ej möjligt att välja fler variabler i mobilläge. Använd desktop för att välja fler variabler.'
											// 	:
											'Välj en eller flera variabler för att visa statistik'
										}
									</Text>
									{current.context.parameters && (
										<div
											className={clsx(
												'inline-block',
												'mb-8 border-2 border-greySemiLight rounded max-w-full'
											)}
										>
											<button
												onClick={() =>
													setParametersExpanded(!parametersExpanded)
												}
												className={clsx(
													parametersExpanded
														? 'flex w-full'
														: 'inline-block text-greyDarker',
													'py-2 px-3 leading-5 justify-between '
												)}
												data-button-action={
													parametersExpanded ? 'Dölj fler val' : 'Visa fler val'
												}
											>
												<span>
													{parametersExpanded
														? 'Dölj fler val'
														: 'Visa fler val'}
												</span>
												<span className="ml-6">
													<Icon
														icon="chevron"
														size={0.75}
														direction={parametersExpanded ? 'down' : 'up'}
														color="#00636A"
													/>
												</span>
											</button>
											{parametersExpanded && (
												<Form
													className="relative border-t border-greySemiLight max-w-full overflow-x-auto"
													onChange={(e: any) => {
														// send({ type: 'SELECT_UNIT', data: e.value });
													}}
												>
													<ScrollableTable disableShadows>
														<div className="flex divide-x divide-greySemiLight max-w-full">
															{current.context.parameters.map(
																(
																	column: OptionDataParameterGroup,
																	colIndex: number
																) => {
																	let isLastColumn =
																		(current.context.parameters &&
																			colIndex ===
																				current.context.parameters.length -
																					1) ||
																		false;
																	return (
																		<div className="w-72" key={colIndex}>
																			{column.heading && (
																				<Text
																					as="h4"
																					className="px-4 pt-2 font-bold"
																				>
																					{column.heading}
																					{column.description && (
																						<Tooltip
																							className="leading-3 align-top font-normal"
																							label="Beskrivning av upphandlare"
																							description={column.description}
																							position={
																								isLastColumn ? 'left' : 'right'
																							}
																							data-button-action={
																								dataButtonActions.tooltip
																							}
																						/>
																					)}
																				</Text>
																			)}
																			{column.text && (
																				<Text as="p" className="px-4">
																					{column.text}
																				</Text>
																			)}
																			{column.dimensions?.map(
																				(
																					dimension: OptionDataParameterDimension,
																					dimIndex: number
																				) => (
																					<React.Fragment key={dimIndex}>
																						{renderParameterGroup(
																							dimension.properties,
																							dimension.heading,
																							current.context
																								.selectedParameters,
																							send,
																							isLastColumn,
																							dimension.description
																						)}
																					</React.Fragment>
																				)
																			)}
																		</div>
																	);
																}
															)}
														</div>
													</ScrollableTable>
												</Form>
											)}
										</div>
									)}
								</div>
							</>
						)}

						<div
							className={clsx(
								'transition-opacity duration-500 ease-in-out border-b border-grey',
								!current.context.time && 'hidden'
							)}
						>
							<Text as="h3">
								Tidsperiod
								<span className="font-normal text-greyDarker ml-1">
									(Obligatoriskt)
								</span>
								<Tooltip
									className="leading-3 align-top"
									label="Beskrivning av tid"
									description="Genom att välja tidsperiod kan du avgränsa statistiken till den period du är intresserad av."
									position={isMobile ? 'center' : 'right'}
									data-button-action={dataButtonActions.tooltip}
								/>
							</Text>
							<Text as="p">Välj tidsperiod</Text>
							{current.context.time?.years && (
								<form className="mb-4">
									<fieldset className="flex flex-wrap">
										{current.context.time.years.map((option) =>
											current.context.time?.years?.length === 1 ? (
												renderRadioButton(
													option,
													option.value === current.context.selectedYear[0],
													(e: any) => {
														send({
															type: e.target.checked
																? 'ADD_YEAR'
																: 'REMOVE_YEAR',
															data: e.target.value,
														});
													}
												)
											) : (
												<CheckboxBase
													id={option.id}
													key={option.id}
													name={option.name}
													value={option.value}
													theme="teal"
													checked={
														current.context.selectedYear.indexOf(
															option.value
														) !== -1
													}
													className="mr-4"
													onChange={(e: any) => {
														send({
															type: e.target.checked
																? 'ADD_YEAR'
																: 'REMOVE_YEAR',
															data: e.target.value,
														});
														handleActionClick(e, 'Tidsperiod', option.text);
													}}
												>
													{option.text}
												</CheckboxBase>
											)
										)}
									</fieldset>
									{current.context.time?.fragments && (
										<fieldset className="flex flex-wrap">
											{current.context.time.fragments.map((option) =>
												renderRadioButton(
													option,
													option.value ===
														current.context.selectedTimeFragment[0],
													(e: any) => {
														send({
															type: 'SET_TIME_FRAGMENT',
															data: e.target.value,
														});
														handleActionClick(e, 'Tidsperiod', option.text);
													}
												)
											)}
										</fieldset>
									)}
								</form>
							)}
						</div>
						{current.context?.measurements && (
							<div
								className={clsx(
									'transition-opacity duration-500 ease-in-out border-b border-grey',
									!current.context.measurements && 'opacity-50'
								)}
							>
								<Text as="h3">
									Mått
									<span className="font-normal text-greyDarker ml-1">
										(Obligatoriskt)
									</span>
								</Text>
								<Text as="p">Välj måttenhet</Text>
								{current.context.measurements && (
									<form className="mb-4">
										<fieldset className="flex flex-wrap">
											{current.context.measurements.map((option) => (
												<RadioButtonBase
													id={option.id}
													key={option.id}
													name={option.name}
													value={option.value}
													theme="teal"
													checked={
														current.context.selectedMeasurement === option.value
													}
													className="mr-4"
													onChange={(e: any) => {
														send({
															type: 'SELECT_MEASUREMENT',
															data: e.target.value,
														});
														handleActionClick(e, 'Mått', option.text);
													}}
												>
													{option.text}
												</RadioButtonBase>
											))}
										</fieldset>
									</form>
								)}
							</div>
						)}
						<div
							className={clsx(
								'text-white py-2 text-center sticky bottom-12 rounded-full transition-colors duration-500 mt-12',
								(current.context.result || current.context.resultError) &&
									'bg-greenDarker',
								!current.context.result &&
									!current.context.resultError &&
									'bg-greyDarker'
							)}
							ref={resultBarRef}
						>
							{current.context.result || current.context.resultError ? (
								<button
									type="button"
									data-button-action={dataButtonActions.resultAvDittUrval}
									onClick={srollToResult}
									className="flex items-center mx-auto"
								>
									Resultat av ditt urval{' '}
									<div className="animate-temporary-bounce">
										<Icon icon="arrow" className="ml-2" direction="down" />
									</div>
								</button>
							) : (
								<button
									type="button"
									data-button-action={dataButtonActions.resultAvDittUrval}
									onClick={srollToResult}
									disabled
									className="cursor-default"
								>
									Resultat av ditt urval{' '}
									<Icon icon="arrow" className="ml-2" direction="down" />
								</button>
							)}
						</div>
						{!current.context.result && !current.context.resultError && (
							<Text as="h3" className="pt-4 pb-24">
								Aktuellt urval ger ingen statistik. Välj en variabel för att
								visa ett resultat av ditt urval.
							</Text>
						)}
					</Cell>
				</Grid>
			</div>
			<div ref={resultDivRef}>
				<Grid margin={false} padding={false} className="lg:px-6 px-4">
					<Cell span={12}>
						<div
							className={clsx(
								'transition-opacity duration-500 ease-in-out border-b border-grey',
								!current.context.filters && 'hidden'
							)}
						>
							<Text as="h3">
								Filtrera
								<Tooltip
									className="leading-3 align-top"
									label="Beskrivning av filtrera"
									description="Beroende på vilka variabler du valt kan du förfina ditt resultat ännu mer genom att filtrera."
									position="right"
									data-button-action={dataButtonActions.tooltip}
								/>
							</Text>
							<Form className="mb-4 block md:flex justify-between">
								<div className="flex flex-wrap">
									<>
										{current.context?.filters?.map(
											(filter: OptionDataFiltersGroup, filterIndex: number) => {
												return (
													<StatisticsServiceFilter
														key={filter.text}
														className="mb-4 mr-4"
														itemsToFetch={10}
														selectedBuckets={filter.selectedBuckets}
														id="filter"
														label={filter.text}
														searchPlaceholder={`Sök på ${filter.text}`}
														showMoreButtonText="Visa fler"
														expandButtonText="Visa resultat"
														collapseButtonText="Dölj resultat"
														searchSubmitLabel="Genomgör sökning"
														send={send}
													>
														<ul className="mb-4">
															{filter.items.map((item: any, index: number) => (
																<StatisticsServiceFilterOption
																	key={index}
																	item={item}
																	onChange={(
																		e: React.FormEvent<HTMLInputElement>
																	) => {
																		const { name, value, checked } =
																			e.currentTarget;
																		if (checked) {
																			send({
																				type: 'SET_FILTER',
																				name,
																				value,
																			});
																		} else {
																			send({
																				type: 'CLEAR_FILTER',
																				name,
																				value,
																			});
																		}
																	}}
																	isChecked={(option: OptionData) =>
																		current.context.selectedFilters.indexOf(
																			option.value
																		) !== -1
																	}
																/>
															))}
														</ul>
													</StatisticsServiceFilter>
												);
											}
										)}
									</>
								</div>
								<ButtonSupportive
									type="button"
									variant="rounded"
									onClick={() => {
										send('RESET_FILTERS');
									}}
								>
									Rensa filter
									<Icon
										icon="trashCan"
										aria-hidden={true}
										className="ml-2 -mt-1"
									/>
								</ButtonSupportive>
							</Form>
						</div>

						{(current.context.result || current.context.resultError) && (
							<div
								className={clsx(
									'transition-opacity duration-500 ease-in-out mb-8'
								)}
							>
								<div className="block md:flex justify-between items-baseline mt-7 mb-2">
									<Text as="h3" margin={false} className="mb-4">
										{current.context.resultHeading}
									</Text>
								</div>
								<div className="mt-6 mb-6">
									<FilterButtonGroup>
										<FilterButton
											isActive={
												current.context.selectedResultFormat === 'table' ||
												current.context.selectedResultFormat === 'matrixtable'
											}
											text="Tabell"
											onClick={() => {
												send({
													type: 'SELECT_RESULT_FORMAT',
													data: 'table',
												});
											}}
											data-button-action={dataButtonActions.tableButton}
										/>
										<FilterButton
											isActive={
												current.context.selectedResultFormat === 'chart'
											}
											text="Diagram"
											onClick={() => {
												send({
													type: 'SELECT_RESULT_FORMAT',
													data: 'chart',
												});
											}}
											data-button-action={dataButtonActions.diagramButton}
										/>
									</FilterButtonGroup>
								</div>

								{(current.context.selectedResultFormat === 'table' ||
									current.context.selectedResultFormat === 'matrixtable') && (
									<div className="flex justify-between mb-2">
										<form className="flex flex-wrap">
											<RadioButtonBase
												id="table-view-table"
												name="viewMode"
												value="table"
												checked={
													current.context.selectedResultFormat === 'table'
												}
												className="mr-4 mb-4"
												onChange={(e: any) => {
													send({
														type: 'SELECT_RESULT_FORMAT',
														data: e.target.value,
													});
												}}
												theme="teal"
												disabled={
													current.context.resultError?.message ? true : false
												}
											>
												Tabell
											</RadioButtonBase>
											<RadioButtonBase
												id="table-view-matrix"
												name="viewMode"
												value="matrixtable"
												checked={
													current.context.selectedResultFormat === 'matrixtable'
												}
												className="mr-4 mb-4"
												onChange={(e: any) => {
													send({
														type: 'SELECT_RESULT_FORMAT',
														data: e.target.value,
													});
												}}
												theme="teal"
												disabled={
													current.context.resultError?.message ? true : false
												}
											>
												Matristabell
											</RadioButtonBase>
										</form>
										<ExportDataDropdown
											buttonText="Exportera resultat"
											disabled={
												current.context.resultError?.message ? true : false
											}
										>
											{tableExportOptions.map((option: any, index: number) => {
												return (
													<ExportDataOption
														type={option.value}
														chartRef={chartRef}
														onClick={() => {
															send({
																type: 'EXPORT_RESULTS',
																value: option.value,
																chartRef: chartRef,
															});
															handleExportData(option.value);
														}}
														key={index}
													>
														{option.caption}
													</ExportDataOption>
												);
											})}
										</ExportDataDropdown>
									</div>
								)}
								{current.context.selectedResultFormat === 'chart' && (
									<div className="flex justify-between mb-2">
										<form className="flex flex-wrap">
											<RadioButtonBase
												id="chart-view-bar"
												name="viewMode"
												value="column"
												checked={
													current.context.selectedChartType === 'column' ||
													current.context.selectedChartType === 'bar'
												}
												className="mr-4 mb-4"
												onChange={(e: any) => {
													send({
														type: 'SELECT_CHART_TYPE',
														data: e.target.value,
													});
												}}
												theme="teal"
												disabled={
													current.context.resultError?.message ? true : false
												}
											>
												Stapeldiagram
											</RadioButtonBase>
											<RadioButtonBase
												id="chart-view-line"
												name="viewMode"
												value="line"
												checked={current.context.selectedChartType === 'line'}
												className="mr-4 mb-4"
												onChange={(e: any) => {
													send({
														type: 'SELECT_CHART_TYPE',
														data: e.target.value,
													});
												}}
												theme="teal"
												disabled={
													current.context.resultError?.message ? true : false
												}
											>
												Linjediagram
											</RadioButtonBase>
											<RadioButtonBase
												id="chart-view-pie"
												name="viewMode"
												value="pie"
												checked={current.context.selectedChartType === 'pie'}
												className="mr-4 mb-4"
												onChange={(e: any) => {
													send({
														type: 'SELECT_CHART_TYPE',
														data: e.target.value,
													});
												}}
												theme="teal"
												disabled={
													current.context.resultError?.message ? true : false
												}
											>
												Cirkeldiagram
											</RadioButtonBase>
										</form>
										<ExportDataDropdown
											buttonText="Exportera resultat"
											disabled={
												current.context.resultError?.message ? true : false
											}
										>
											{diagramExportOptions.map(
												(option: any, index: number) => {
													return (
														<ExportDataOption
															type={option.value}
															chartRef={chartRef}
															onClick={() => {
																send({
																	type: 'EXPORT_RESULTS',
																	value: option.value,
																	chartRef: chartRef,
																});
																handleExportData(option.value);
															}}
															key={index}
														>
															{option.caption}
														</ExportDataOption>
													);
												}
											)}
										</ExportDataDropdown>
									</div>
								)}

								{/* {current.context.selectedResultFormat !== 'table' &&
									current.context.variables && (
										<form className="mt-8 mb-20 max-w-xs">
											<Label htmlFor="select-variable">Välj variabel</Label>
											<SelectBase
												name="variable"
												id="variable"
												onChange={(e: any) => {
													send({
														type: 'SELECT_VARIABLE',
														data: e.target.value,
													});
												}}
												value={current.context.selectedVariable || undefined}
											>
												{current.context.variables.map(
													({ id, text, value }) => {
														return (
															<option value={value} key={id}>
																{text}
															</option>
														);
													}
												)}
											</SelectBase>
										</form>
									)} */}

								{current.context.selectedResultFormat === 'table' &&
									!current.matches('result.loadingStatistics') &&
									!current.context.resultError && (
										<StatisticTable
											data={current.context.result}
											lastUpdated={current.context.lastUpdated}
											pagerText={
												(current.context.result?.rows?.length > 0 &&
													`1-${current.context.result.rows.length} av ${current.context.result.total}`) ||
												''
											}
											enableSort={true}
											enablePagination={
												current.context.numItemsTotal >
												current.context.numItemsToFetch
											}
											sortCallback={(prop: string, dir: string) => {
												send('CHANGE_ORDER', {
													orderBy: prop,
													orderDirection: dir === '' ? 'ASC' : dir,
												});
											}}
											paginationCallback={() => {
												send('LOAD_MORE');
											}}
										/>
									)}
								{current.context.selectedResultFormat === 'matrixtable' &&
									!current.matches('result.loadingStatistics') &&
									!current.context.resultError && (
										<StatisticTable
											renderTotal={
												current.context.selectedMeasurement === 'SUM'
											}
											data={current.context.result}
											lastUpdated={current.context.lastUpdated}
											pagerText={
												(current.context.result?.rows?.length > 0 &&
													`1-${current.context.result.rows.length - 1} av ${
														current.context.result.total
													}`) ||
												''
											}
											matrix={true}
											enableSort={true}
											enablePagination={
												current.context.numItemsTotal >
												current.context.numItemsToFetch
											}
											sortCallback={(prop: string, dir: string) => {
												send('CHANGE_ORDER', {
													orderBy: prop,
													orderDirection: dir === '' ? 'ASC' : dir,
												});
											}}
											paginationCallback={() => {
												send('LOAD_MORE');
											}}
										/>
									)}
								{current.matches('result.loadingStatistics') && (
									<div className="flex justify-around">
										<Icon
											icon="loader"
											size={4}
											animate="spin"
											color="#75726B"
											className="inline-block ml-2"
										/>
									</div>
								)}
								{current.context.selectedResultFormat === 'chart' &&
									!current.context.resultError && (
										<StatisticServiceChart
											data={current.context.result}
											lastUpdated={current.context.lastUpdated}
											chartRef={chartRef}
											send={send}
											isLoading={current.matches('result.loadingStatistics')}
										/>
									)}

								<div
									aria-live="polite"
									aria-atomic="true"
									className="md:w-1/2 lg:w-4/12 my-6"
								>
									{!current.matches('result.loadingStatistics') &&
										current.context.resultError?.message && (
											<Alert variant="warning">
												<Text as="p" margin={false} className="mb-0">
													{current.context.resultError?.message}
												</Text>
											</Alert>
										)}
								</div>
							</div>
						)}
					</Cell>
				</Grid>
			</div>
		</div>
	);
};

export default StatisticService;
